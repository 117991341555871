import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const About_2 = props => {
    const data = useStaticQuery(graphql`
        query Office2 {
            file(relativePath: { eq: "offices/A2_Kontor.jpg" }) {
                childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
                }
            }
        }
    `)

    return (
        <Img 
            {...props}
            fluid={data.file.childImageSharp.fluid} 
        />
    )
}


export default About_2
